import {
  makeResponsive,
  //makeResponsive(brd1);
  placeTitle,
  //placeTitle(brd1,'title', 'subtitle');
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  //placeArrow(board, pt1, pt2, width, cols)
  placeGravity,
  placeText,
  //placeText(board, positionX, positionY, message)
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeSliderSwitch,
  placeBoldText,
  //placeBoldText(brd6, -9, 1, 'Liquid');
  placeDash,
  // placeDash(board, [x1, y1], [x2, y2], width, cols)
  placeRightText,
  placeLabel
} from '../../../common/edliy_utils-energy';
const Boxes = {
    box1: function () {
    //Create the Board
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-2, 11, 12, -3],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
   //suspend updates
    brd1.suspendUpdate();
    // Layering
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    //Set CSS
    brd1.options.text.cssDefaultStyle = 'fontFamily:Oswald;'
    brd1.options.text.highlightCssDefaultStyle = 'fontFamily:Oswald';
    //Set Highlight
    brd1.options.text.highlight = false;
    brd1.options.line.highlight=false;
    brd1.options.arrow.highlight=false;
    brd1.options.point.highlight=false;
    brd1.options.arc.highlight=false;
//   Fixing
    brd1.options.text.fixed=true;
//    JXG.Options.point.fixed=true;
    brd1.options.layer['image']=17;
    brd1.options.layer['text']=16;
    //brd1.options.layer['line']=14;
    brd1.options.layer['point']=15;
    brd1.options.layer['glider']=15;
    //Make the board responsive
    makeResponsive(brd1);
    // Creat titleStyle
    placeTitle(brd1, 'Faraday\'s Law of Induction', 'Magnetic Flux and Electromotive Force');
    //dragMe(brd1, 5, 5);
    //var xaxis = brd1.create('line',[[0,0], [0.5, 0]],{visible:false});
    //Title and tetxs
    //var title1 = brd1.create('text', [5, 8.5, "<b> Faraday's Law of Induction <b>"],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(26*brd1.canvasWidth/500.)}},);

    //Tetxts
    var txt1 = brd1.create('text', [1, 5,  'Coil'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
    var txt2 = brd1.create('text', [5, 7.5,  'Magnetic Field (B)'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
    var movet = placeGravity(brd1, [1.65, 5],[3, 5], 'v');
  //  move.setAttribute({visible:})
    //Sliders
    //var magField = brd1.create('slider',[[8,7],[10,7],[-1,0.5,1]], {snapToGrid: false ,face:'square', size:4, strokeWidth: 2, name:'B [T]', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    var magField =placeSlider(brd1, 8, 7, -1, 1, 1, 2, '&nbsp;&nbsp;B', '&nbsp; T');
    magField.setAttribute({snapWidth:1})
    //Spire step 1
    var spireStep1 = brd1.create('polygon', [[0,4],[2,4], [2,6], [0,6] ], {fixed: false, visible: true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 3}, fillColor: 'white', fillOpacity:0.3});

    //Spire step 2
    var pSpireStep2 = brd1.create('point', [0, 4],{snapWidth: 2, snapToGrid: true,  face:'o' , name:'', size:3, strokeColor: 'red' ,fillColor: 'red' , fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

    var sp1 = brd1.create('polygon', [[function(){return pSpireStep2.X()},function(){return pSpireStep2.Y()}], [function(){return pSpireStep2.X() +1}, function(){return pSpireStep2.Y()}], [function(){return pSpireStep2.X() +1}, function(){return pSpireStep2.Y()+1}], [function(){return pSpireStep2.X()},function(){return pSpireStep2.Y()+1}]], {fixed: false, visible: false, vertices:{visible:false, fixed:true}, borders:{visible:false, strokeColor: 'black', strokeWidth: 3}, fillColor: 'grey', fillOpacity:0.3});
    var sp2 = brd1.create('polygon', [[function(){return pSpireStep2.X()+1},function(){return pSpireStep2.Y()}], [function(){return pSpireStep2.X()+2},function(){return pSpireStep2.Y()}], [function(){return pSpireStep2.X()+2},function(){return pSpireStep2.Y()+1}], [function(){return pSpireStep2.X()+1},function(){return pSpireStep2.Y()+1}]], {fixed: false, visible: false, vertices:{visible:false, fixed:true}, borders:{visible:false, strokeColor: 'black', strokeWidth: 3}, fillColor: 'grey', fillOpacity:0.3});
    var sp3 = brd1.create('polygon', [[function(){return pSpireStep2.X()+1},function(){return pSpireStep2.Y()+1}], [function(){return pSpireStep2.X()+2},function(){return pSpireStep2.Y()+1}], [function(){return pSpireStep2.X()+2},function(){return pSpireStep2.Y()+2}], [function(){return pSpireStep2.X()+1},function(){return pSpireStep2.Y()+2}]], {fixed: false, visible: false, vertices:{visible:false, fixed:true}, borders:{visible:false, strokeColor: 'black', strokeWidth: 3}, fillColor: 'grey', fillOpacity:0.3});
    var sp4 = brd1.create('polygon', [[function(){return pSpireStep2.X()},function(){return pSpireStep2.Y()+1}], [function(){return pSpireStep2.X() +1}, function(){return pSpireStep2.Y()+1}], [function(){return pSpireStep2.X() +1}, function(){return pSpireStep2.Y()+2}], [function(){return pSpireStep2.X()},function(){return pSpireStep2.Y()+2}]], {fixed: false, visible: false, vertices:{visible:false, fixed:true}, borders:{visible:false, strokeColor: 'black', strokeWidth: 3}, fillColor: 'grey', fillOpacity:0.3});

    var txtsp1 = brd1.create('text', [function(){return pSpireStep2.X() + 0.5}, function(){return pSpireStep2.Y() + 0.25},  'A_1'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
    var txtsp2 = brd1.create('text', [function(){return pSpireStep2.X() + 1.5}, function(){return pSpireStep2.Y() + 0.25},  'A_2'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
    var txtsp3 = brd1.create('text', [function(){return pSpireStep2.X() + 1.5}, function(){return pSpireStep2.Y() + 1.25},  'A_3'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
    var txtsp4 = brd1.create('text', [function(){return pSpireStep2.X() + 0.5}, function(){return pSpireStep2.Y() + 1.25},  'A_4'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);

    //Spire step 3
    var segmentSP = brd1.create('segment', [[2, 4], [15, 4]], {fixed: true, strokeColor: 'black', strokeWidth: 3, visible: false});

    var pSpire2 = brd1.create('glider', [2, 8, segmentSP ],{snapToGrid: false, face:'o' , name:'', size:3, strokeColor: 'red' ,fillColor: 'red' , fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    var pSpire1 = brd1.create('point', [function(){return pSpire2.X()-2}, function(){return pSpire2.Y()}],{face:'o' , name:'', size:3, strokeColor: 'red' ,fillColor: 'red' , fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    var pSpire3 = brd1.create('point', [function(){return pSpire2.X()}, function(){return pSpire2.Y()+2} ],{face:'o' , name:'', size:3, strokeColor: 'red' ,fillColor: 'red' , fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    var pSpire4 = brd1.create('point', [function(){return pSpire2.X() -2}, function(){return pSpire2.Y()+2} ],{face:'o' , name:'', size:3, strokeColor: 'red' ,fillColor: 'red' , fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

    var spireStep3 = brd1.create('polygon', [pSpire1, pSpire2, pSpire3, pSpire4], {fixed: false, visible: false, vertices:{visible:false, fixed:true}, borders:{visible:false, strokeColor: 'black', strokeWidth: 3}, fillColor: 'white', fillOpacity:0.3});



    //Magnetic Field
    var points1 = [];
    var images1 = [];
    var arrows1 = [];

    var x0 = 2.5;
    var y0= 0.5;

    var xmax = 5;
    var ymax = 8;

    for(let j=1; j<ymax ; j++){

        var pi1 = [];
        var ii1 = [];
        var ai1 = [];


        for(let i=1; i<xmax ; i++){

            var pt11 = brd1.create('point', [x0 + i - 0.5 , y0 +j - 0.5 ],{face: 'o' , name: ' ', size:0, strokeColor: 'green' ,fillColor: 'green' , fixed:true, visible:false, label:{offset: [10, 15], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
            //var im11 = brd1.create('image', [function(){if(magField.Value() > 0) {return '/assets/out.svg'} else{return '/assets/in.svg'}}, [x0 + i - 0.25 , y0 +j -0.25 ], [0.5 , 0.5]], {visible: false, fixed: true});
            var arr11 = brd1.create('image', [function(){if(magField.Value() > 0)
              {return '/assets/out.svg'} else{return '/assets/in.svg'}}, [x0 + i - 0.25 , y0 +j -0.25 ], [function(){return 0.5*magField.Value()}, function(){return 0.5*magField.Value()}]], {visible: true, fixed: true});

            pi1.push(pt11);
            ii1.push(arr11);
            ai1.push(arr11);
        }

        points1.push(pi1);
        images1.push(ii1);
        arrows1.push(ai1);
    }

    //Flux
    var heightSpire = 2;

    var flux_previous = 0;
    var deltaFlux = 0;

    var flux = function(){

        checkMovement();

        var baseSpire = heightSpire;

        if(pSpire2.X() <=3){
            baseSpire = 0;
        }

        else if(pSpire2.X() > 3 && pSpire2.X() <= 5){
            baseSpire = pSpire2.X() - 5;
        }
        else if(pSpire2.X() > 5 && pSpire2.X() <= 7){
            baseSpire = 2;
        }
        else if(pSpire2.X() > 7 && pSpire2.X() <= 9){
            baseSpire = (7 - pSpire1.X());
        }
        else{
            baseSpire = 0;
        }

        var f = magField.Value()*(baseSpire*heightSpire);
        deltaFlux = f - flux_previous;
        flux_previous = f;


        return f;

    }

    //EMF
    var x_previous = pSpire1.X()

    var deltaX = 0;
    var deltaT = 1;

    var speed = 0;
    var t0 = Date.now(); //miliseconds since 1970

    function checkMovement(){

        deltaX = pSpire1.X() - x_previous;

        x_previous = pSpire1.X()


        deltaT = Date.now() - t0;
        speed = (deltaX/deltaT)*1000;
        t0 = Date.now();
    }

    //Current

    var resistance = 1;

    var current = function(){return (-deltaFlux/(deltaT*resistance)*1000).toFixed(2) };

    var segment1 = brd1.create('segment', [function(){if(current() > 0) {return pSpire1} else {return pSpire2}}, function(){if(current() > 0) {return pSpire2} else {return pSpire1}}], {fixed: true, strokeColor: 'grey', visible: false});
    var segment2 = brd1.create('segment', [function(){if(current() > 0) {return pSpire2} else {return pSpire3}}, function(){if(current() > 0) {return pSpire3} else {return pSpire2}}], {fixed: true, strokeColor: 'grey', visible: false});
    var segment3 = brd1.create('segment', [function(){if(current() > 0) {return pSpire3} else {return pSpire4}}, function(){if(current() > 0) {return pSpire4} else {return pSpire3}}], {fixed: true, strokeColor: 'grey', visible: false});
    var segment4 = brd1.create('segment', [function(){if(current() > 0) {return pSpire4} else {return pSpire1}}, function(){if(current() > 0) {return pSpire1} else {return pSpire4}}], {fixed: true, strokeColor: 'grey', visible: false});

    var current1 =brd1.create('glider', [8, 0, segment1], {strokeColor: 'black' , fillColor: 'black' , face: '>', size:0, name:'', visible: false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    var current2 =brd1.create('glider', [16, 0, segment2], {strokeColor: 'black' , fillColor: 'black' , face: '^', size:0, name:'', visible: false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    var current3 =brd1.create('glider', [8, 10, segment3], {strokeColor: 'black' , fillColor: 'black' , face: '<', size:0, name:'', visible: false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    var current4 =brd1.create('glider', [16, 0, segment4], {strokeColor: 'black' , fillColor: 'black' , face: 'v', size:0, name:'', visible: false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

    var imArr1 = brd1.create('image', [function(){if(current() > 0) {return '/assets/rightArrow.svg'} else if(current() <= 0){return '/assets/leftArrow.svg'}}, [function(){return current1.X()-0.15}, function(){return current1.Y() - 0.15}], [0.3 , 0.3]], {visible: false, fixed: true});
    var imArr2 = brd1.create('image', [function(){if(current() > 0) {return '/assets/upArrow.svg'} else if(current() <= 0){return '/assets/downArrow.svg'}}, [function(){return current2.X()-0.15}, function(){return current2.Y() - 0.15}], [0.3 , 0.3]], {visible: false, fixed: true});
    var imArr3 = brd1.create('image', [function(){if(current() > 0) {return '/assets/leftArrow.svg'} else if(current() <= 0){return '/assets/rightArrow.svg'}}, [function(){return current3.X()-0.15}, function(){return current3.Y() - 0.15}], [0.3 , 0.3]], {visible: false, fixed: true});
    var imArr4 = brd1.create('image', [function(){if(current() > 0) {return '/assets/downArrow.svg'} else if(current() <= 0){return '/assets/upArrow.svg'}}, [function(){return current4.X()-0.15}, function(){return current4.Y() - 0.15}], [0.3 , 0.3]], {visible: false, fixed: true});

    function startCurrent(){


        current1.startAnimation(1, 100, 30);
        current2.startAnimation(1, 100, 30);
        current3.startAnimation(1, 100, 30);
        current4.startAnimation(1, 100, 30);

    }

    function stopCurrent(){

        current1.stopAnimation(1, 16, 330);
        current2.stopAnimation(1, 16, 330);
        current3.stopAnimation(1, 16, 330);
        current4.stopAnimation(1, 16, 330);


    }

    //Tetx
    var txtPHI2 = brd1.create('text', [5, 1,  function(){if(pSpireStep2.X() <= 1 || pSpireStep2.X() >= 7 || pSpireStep2.Y() <= 1 || pSpireStep2.Y() >= 7 ){return '&phi; = 0A_1 + 0A_2 + 0A_3 + 0A_4 '}
                                                        else if(pSpireStep2.X() == 2 && pSpireStep2.Y() == 2){return '&phi; = 0A_1 + 0A_2 + BA_3 + 0A_4 '}
                                                        else if(pSpireStep2.X() >= 3 && pSpireStep2.X() <= 5 && pSpireStep2.Y() == 2){return '&phi; = 0A_1 + 0A_2 + BA_3 + BA_4 '}
                                                        else if(pSpireStep2.X() == 6 && pSpireStep2.Y() == 2 ){return '&phi; = 0A_1 + 0A_2 + 0A_3 + BA_4 '}
                                                        else if(pSpireStep2.X() >= 3 && pSpireStep2.X() <= 5 && pSpireStep2.Y() >= 3 &&pSpireStep2.Y() <= 5 ){return '&phi; = BA_1 + BA_2 + BA_3 + BA_4 '}
                                                        else if(pSpireStep2.X() == 2 && pSpireStep2.Y() == 6){return '&phi; = 0A_1 + BA_2 + 0A_3 + 0A_4 '}
                                                        else if(pSpireStep2.X() >= 3 && pSpireStep2.X() <= 5 && pSpireStep2.Y() == 5){return '&phi; = BA_1 + BA_2 + 0A_3 + 0A_4 '}
                                                        else if(pSpireStep2.X() >= 3 && pSpireStep2.X() <= 5 && pSpireStep2.Y() == 6){return '&phi; = BA_1 + BA_2 + 0A_3 + 0A_4 '}
                                                        else if(pSpireStep2.X() == 6 && pSpireStep2.Y() == 6 ){return '&phi; = BA_1 + 0A_2 + 0A_3 + 0A_4 '}
                                                        else if(pSpireStep2.X() == 2 && pSpireStep2.Y() >= 3 && pSpireStep2.Y() <= 5 ){return '&phi; = 0A_1 + BA_2 + BA_3 + 0A_4 '}
                                                        else if(pSpireStep2.X() == 6 && pSpireStep2.Y() >= 3 && pSpireStep2.Y() <= 5 ){return '&phi; = BA_1 + 0A_2 + 0A_3 + BA_4 '}

                                                        else{return ' '}


    }],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}},);
    var txtPHI22 = brd1.create('text', [2, 1,  function(){return '&phi; = ∫BdA →' }],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}},);

    var txtVEL3 = brd1.create('text', [8.9, 6,  function(){return 'v = ' + speed.toFixed(2) + ' m/s'}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(15*brd1.canvasWidth/500.)}},);
    var txtPHI3 = brd1.create('text', [8.9, 5,  function(){return '&phi; =  ' + flux().toFixed(2) +' Wb' }],{visible: false, fixed: true, color: 'black', anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(15*brd1.canvasWidth/500.)}},);

    var txtEMF3 = brd1.create('text', [9, 4,  function(){return '&epsilon; = -<u> Δ&phi; </u> = ' + (-deltaFlux/deltaT*1000).toFixed(2) + ' V'}],{visible: false, fixed: true,  color: 'black', anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(15*brd1.canvasWidth/500.)}},);
    var txtEMF33 = brd1.create('text', [8.9, 3.7,  'Δt'],{visible: false,  color: 'black', fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(15*brd1.canvasWidth/500.)}},);

    var txtI3 = brd1.create('text', [8.5, 2.5,  ' I ='],{visible: false,  color: 'black', fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(15*brd1.canvasWidth/500.)}},);
    var txtI33 = brd1.create('text', [9, 2.7,  ' <u> &epsilon; </u> '],{visible: false,  color: 'black', fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(15*brd1.canvasWidth/500.)}},);
    var txtI333 = brd1.create('text', [9, 2.2,  'R'],{visible: false,  color: 'black', fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(15*brd1.canvasWidth/500.)}},);
    var txtI3333 = brd1.create('text', [9.5, 2.5,  function(){return '=' + current()}],{visible: false,  color: 'black', fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(15*brd1.canvasWidth/500.)}},);

    //Graph
    var  graphFlux = brd1.create('functiongraph',
                           [function(x){
                                        if(x <= 3){
                                            return 0;
                                        }
                                        else if(x > 3 && x <= 5){
                                            return 2*magField.Value()*x + magField.Value()*(-6)
                                        }
                                        else if(x > 5 && x <= 7){
                                            return 4*magField.Value();
                                        }
                                        else if(x > 7 && x <= 9){
                                            return -2*magField.Value()*x + magField.Value()*(+18)
                                        }
                                        else{
                                            return 0;
                                        }
    }, 0, function(){return pSpire2.X()}] , {visible: false, strokeColor: function(x){
                                                                                        if(x <= 5){
                                                                                            return 'green';
                                                                                        }
                                                                                        else if(x > 5 && x <= 7){
                                                                                            return 'red'
                                                                                        }
                                                                                        else if(x > 7 && x <= 10){
                                                                                            return 'red'
                                                                                        }
                                                                                        else if(x > 10 && x <= 12){
                                                                                            return 'green'
                                                                                        }
                                                                                        else{
                                                                                            return 'green';
                                                                                        }}, strokeWidth: 3}
    );



    //REDO AND UNDO
    var redo = brd1.create('image', ['/assets/redo.svg', [5.5, -1.5], [1 , 1]], {visible: true, fixed: true});
    var undo = brd1.create('image', ['/assets/undo.svg', [function(){return redo.X()-2}, function(){return redo.Y()}], [1 , 1]], {visible: true, fixed: true});

    redo.setLabel('Redo')
    redo.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
    redo.on('over', function () {this.label.setAttribute({visible:true});});
    redo.on('out', function () {this.label.setAttribute({visible:false});});

    undo.setLabel('undo')
    undo.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
    undo.on('over', function () {this.label.setAttribute({visible:true});});
    undo.on('out', function () {this.label.setAttribute({visible:false});});

    var s=0;
    var maxSteps = 2;

    function updateSteps(){
        eraseEverything();

        if (s<=0){

            txt1.setAttribute({visible: true});
            txt2.setAttribute({visible: true});
            movet[0].setAttribute({visible:true});
            movet[1].setAttribute({visible:true});
            spireStep1.setAttribute({visible: true, borders:{visible:true}});


           for(let j=1; j<ymax; j++){
                for(let i=1; i<xmax ; i++){
                    arrows1[j-1][i-1].setAttribute({visible: true});
                }
            }

        }

        if (s==1){

            stopCurrent();


           for(let j=1; j<ymax; j++){
                for(let i=1; i<xmax ; i++){
                    images1[j-1][i-1].setAttribute({visible: true});
                    points1[j-1][i-1].setAttribute({visible: true, name: 'B'});
                }
            }

            pSpireStep2.setAttribute({visible: true});

            pSpire2.setAttribute({visible: false});
            txtPHI2.setAttribute({visible: true});
            txtPHI22.setAttribute({visible: true});

            txtsp1.setAttribute({visible: true});
            txtsp2.setAttribute({visible: true});
            txtsp3.setAttribute({visible: true});
            txtsp4.setAttribute({visible: true});

            sp1.setAttribute({visible: true, borders: {visible: true}});
            sp2.setAttribute({visible: true, borders: {visible: true}});
            sp3.setAttribute({visible: true, borders: {visible: true}});;
            sp4.setAttribute({visible: true, borders: {visible: true}});


        }

        if(s==2){



            startCurrent();

            imArr1.setAttribute({visible: true});
            imArr2.setAttribute({visible: true});
            imArr3.setAttribute({visible: true});
            imArr4.setAttribute({visible: true});


            for(let j=1; j<ymax; j++){
                for(let i=1; i<xmax ; i++){
                    images1[j-1][i-1].setAttribute({visible: true});
                    points1[j-1][i-1].setAttribute({visible: false, name: ' '});
                }
            }

            spireStep3.setAttribute({visible: true});

            spireStep3.setAttribute({visible: true, borders:{visible:true}});
            pSpire2.setAttribute({visible: true});

            txtVEL3.setAttribute({visible: true});
            txtPHI3.setAttribute({visible: true});
            txtEMF3.setAttribute({visible: true});
            txtEMF33.setAttribute({visible: true});
            graphFlux.setAttribute({visible: true});

            txtI3.setAttribute({visible: true});
            txtI33.setAttribute({visible: true});
            txtI333.setAttribute({visible: true});
            txtI3333.setAttribute({visible: true});



        }

    }

    function eraseEverything(){

        pSpireStep2.moveTo([0,4], 10);
        pSpire2.moveTo([0,4], 10);

        txt1.setAttribute({visible: false});
        txt2.setAttribute({visible: false});
        movet[0].setAttribute({visible: false});
        movet[1].setAttribute({visible: false});
        spireStep3.setAttribute({visible: false, borders:{visible:false}});
        spireStep1.setAttribute({visible: false, borders:{visible:false}});
        pSpireStep2.setAttribute({visible: false});

        txtI3.setAttribute({visible: false});
        txtI33.setAttribute({visible: false});
        txtI333.setAttribute({visible: false});
        txtI3333.setAttribute({visible: false});


        for(let j=1; j<ymax ; j++){
            for(let i=1; i<xmax ; i++){
               arrows1[j-1][i-1].setAttribute({visible: false});
                images1[j-1][i-1].setAttribute({visible: false});
                points1[j-1][i-1].setAttribute({visible: false, name: ' '});

            }
        }

        txtPHI2.setAttribute({visible: false});
        txtPHI22.setAttribute({visible: false});

        txtVEL3.setAttribute({visible: false});
        txtPHI3.setAttribute({visible: false});
        txtEMF3.setAttribute({visible: false});
        txtEMF33.setAttribute({visible: false});
        graphFlux.setAttribute({visible: false});

        sp1.setAttribute({visible: false, borders: {visible: false}});
        sp2.setAttribute({visible: false, borders: {visible: false}});
        sp3.setAttribute({visible: false, borders: {visible: false}});
        sp4.setAttribute({visible: false, borders: {visible: false}});

        txtsp1.setAttribute({visible: false});
        txtsp2.setAttribute({visible: false});
        txtsp3.setAttribute({visible: false});
        txtsp4.setAttribute({visible: false});

        imArr1.setAttribute({visible: false});
        imArr2.setAttribute({visible: false});
        imArr3.setAttribute({visible: false});
        imArr4.setAttribute({visible: false});


    }

    redo.on('down',function(){
        s = s+1;
        if (s > maxSteps){
            s = maxSteps;
        }
        updateSteps();
    });

    undo.on('down',function(){
        s=s-1;
        if (s < 0){
            s = 0;
        }
        eraseEverything();
        updateSteps();
    });




    brd1.unsuspendUpdate();
                      },
              }
export default Boxes;
